<template>
  <div id="create-company" class="create-company">
    <vs-card class="card">
      <div slot="header">
        <h3 class="pl-2">
          {{ lang.companies.create.title[languageSelected] }}
        </h3>
      </div>
      <!-- <vs-progress :percent="progress" color="success" /> -->
      <div class="form-wrapper p-2">
        <!-- company name -->
        <section class="form-group pb-6">
          <vs-input
            id="companyName"
            v-model="companyName"
            :label="
              lang.companies.create.form.companyName.label[languageSelected]
            "
            :placeholder="
              lang.companies.create.form.companyName.placeholder[
                languageSelected
              ]
            "
            name="companyName"
            v-validate="'required'"
            :class="{
              'is-invalid': submitted && errors.first('companyName')
            }"
          />
          <span
            class="error-msg"
            v-if="submitted && errors.first('companyName')"
          >
            {{ lang.companies.create.form.companyName.error[languageSelected] }}
          </span>
        </section>

        <!-- user name -->
        <section class="form-group pb-6">
          <vs-input
            id="username"
            type="email"
            v-model="username"
            :label="lang.companies.create.form.username.label[languageSelected]"
            :placeholder="
              lang.companies.create.form.username.placeholder[languageSelected]
            "
            name="username"
            v-validate="'required|email'"
            :class="{ 'is-invalid': submitted && errors.first('username') }"
          />
          <span class="error-msg" v-if="submitted && errors.first('username')">
            {{ lang.companies.create.form.username.error[languageSelected] }}
          </span>
        </section>

        <!-- language -->
        <section class="form-group pb-6">
          <label for="language" class="vs-input--label">
            {{ lang.general.cultureOptions.title[languageSelected] }}
          </label>
          <ul class="flex flex-row pt-2" id="language">
            <li class="mr-4">
              <vs-radio v-model="language" vs-name="language" vs-value="es">
                {{ lang.general.cultureOptions.spanish[languageSelected] }}
              </vs-radio>
            </li>
            <li class="mr-4">
              <vs-radio v-model="language" vs-name="language" vs-value="en">
                {{ lang.general.cultureOptions.english[languageSelected] }}
              </vs-radio>
            </li>
            <li>
              <vs-radio v-model="language" vs-name="language" vs-value="pt">
                {{ lang.general.cultureOptions.portuguese[languageSelected] }}
              </vs-radio>
            </li>
          </ul>
        </section>

        <!-- use staff management -->
        <section class="form-group pb-6">
          <label for="useStaffManagement" class="vs-input--label">
            {{ lang.companies.create.form.useStaffManagement[languageSelected] }}
          </label>
          <vx-slider
            v-model="useStaffManagement"
            vs-value="useStaffManagement"
            name="useStaffManagement"
          />
        </section>

        <!-- password -->
        <section class="form-group pb-6 relative">
          <vs-input
            id="password"
            type="password"
            v-model="password"
            :label="lang.companies.create.form.password.label[languageSelected]"
            name="password"
            v-validate="'required'"
            :class="{
              'is-invalid':
                submitted && (errors.first('password') || hasWeakness),
              'is-valid': submitted && !errors.first('password') && !hasWeakness
            }"
            @input="checkPassword"
            @focus="togglePasswordStrength"
            @blur="togglePasswordStrength"
          />
          <span
            :title="lang.companies.create.form.password.show[languageSelected]"
            @mousedown="togglePassword"
            @mouseup="togglePassword"
            class="absolute toggle-password cursor-pointer"
          >
            <vs-icon
              :icon="passwordType === 'password' ? 'icon-eye' : 'icon-eye-off'"
              icon-pack="feather"
              size="small"
            />
          </span>

          <p class="error-msg" v-if="submitted && errors.first('password')">
            {{
              lang.companies.create.form.password.error.required[
                languageSelected
              ]
            }}
          </p>
          <p class="error-msg" v-if="submitted && hasWeakness">
            {{
              lang.companies.create.form.password.error.strength[
                languageSelected
              ]
            }}
          </p>

          <!-- password strength -->
          <transition name="fade" :duration="1000">
            <div
              class="con-vs-dropdown--menu vs-dropdown-menu absolute"
              v-if="showPasswordStrength && hasWeakness"
            >
              <div class="vs-dropdown--custom vs-dropdown--menu">
                <strong>{{
                  lang.companies.create.form.password.validations.strength[
                    languageSelected
                  ]
                }}</strong>
                <ul class="password-strength pt-4 pl-4">
                  <li
                    :class="{
                      is_valid: passwordValidations.containsEightCharacters
                    }"
                  >
                    {{
                      lang.companies.create.form.password.validations
                        .containsEightCharacters[languageSelected]
                    }}
                  </li>
                  <!-- <li :class="{ is_valid: passwordValidations.containsNumber }">
                    {{
                      lang.companies.create.form.password.validations
                        .containsNumber[languageSelected]
                    }}
                  </li>
                  <li
                    :class="{ is_valid: passwordValidations.containsUppercase }"
                  >
                    {{
                      lang.companies.create.form.password.validations
                        .containsUppercase[languageSelected]
                    }}
                  </li>
                  <li
                    :class="{
                      is_valid: passwordValidations.containsSpecialCharacter
                    }"
                  >
                    {{
                      lang.companies.create.form.password.validations
                        .containsSpecialCharacter[languageSelected]
                    }}
                  </li> -->
                </ul>
              </div>
              <div class="vs-dropdown--menu--after" style="top: 10px;"></div>
            </div>
          </transition>
        </section>

        <!-- confirm password -->
        <section class="form-group pb-6">
          <vs-input
            id="confirmPassword"
            type="password"
            v-model="confirmPassword"
            :label="
              lang.companies.create.form.confirmPassword.label[languageSelected]
            "
            name="confirmPassword"
            v-validate="'required'"
            :class="{
              'is-invalid': submitted && errors.first('confirmPassword')
            }"
          />
          <span
            class="error-msg"
            v-if="submitted && errors.first('confirmPassword')"
          >
            {{
              lang.companies.create.form.confirmPassword.error.required[
                languageSelected
              ]
            }}
          </span>
          <p
            class="error-msg"
            v-if="submitted && errors.first('confirmPassword')"
          >
            {{
              lang.createCompany.form.confirmPassword.error.mismatch[
                languageSelected
              ]
            }}
          </p>
          <p
            class="error-msg"
            v-if="
              submitted &&
                password !== null &&
                confirmPassword !== null &&
                password !== confirmPassword
            "
          >
            {{
              lang.companies.create.form.confirmPassword.error.mismatch[
                languageSelected
              ]
            }}
          </p>
        </section>
      </div>

      <div class="form-actions pt-4 pr-2 pb-2">
        <vs-row vs-justify="flex-end">
          <vs-button
            type="flat"
            color="primary"
            class="mr-4"
            @click="reset"
            id="reset"
          >
            {{ lang.general.reset[languageSelected] }}
          </vs-button>
          <vs-button
            type="flat"
            color="danger"
            class="mr-4"
            @click="cancel"
            id="cancel"
          >
            {{ lang.general.cancel[languageSelected] }}
          </vs-button>
          <vs-button color="success" @click="saveCompany" name="save" id="save">
            {{ lang.general.save[languageSelected] }}
          </vs-button>
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Validator } from 'vee-validate'
import es from 'vee-validate/dist/locale/es'
import en from 'vee-validate/dist/locale/en'
import CompanyService from '@/services/company.service'
import { sha256 } from 'js-sha256'
import Validations from '@/store/Validations'
import { errorHandler } from '@/services/ErrorHandler'

export default {
  name: 'CreateCompany',
  data() {
    return {
      companyName: null,
      username: null,
      password: null,
      confirmPassword: null,
      language: 'es',
      useStaffManagement: true,

      submitted: false,

      passwordValidations: {
        containsEightCharacters: false
        // containsNumber: false,
        // containsUppercase: false,
        // containsSpecialCharacter: false
      },
      validPassword: false,
      passwordType: 'password',
      showPasswordStrength: false
    }
  },
  watch: {
    languageSelected() {
      this.updateValidator()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    hasWeakness() {
      return !this.passwordValidations.containsEightCharacters
      // ||
      // !this.passwordValidations.containsNumber ||
      // !this.passwordValidations.containsUppercase ||
      // !this.passwordValidations.containsSpecialCharacter
    },
    progress() {
      let p = 20

      p += this.companyName ? 20 : 0
      p += this.username && Validations.isValidEmail(this.username) ? 20 : 0
      p += this.password && !this.hasWeakness ? 20 : 0
      p +=
        this.confirmPassword &&
        !this.hasWeakness &&
        this.password === this.confirmPassword
          ? 20
          : 0

      return p
    }
  },
  methods: {
    async saveCompany() {
      this.submitted = true
      const valid =
        (await this.$validator.validate()) &&
        !this.hasWeakness &&
        this.password === this.confirmPassword
      if (!valid) return

      try {
        this.$vs.loading.close('#loading-border > .con-vs-loading')

        const payload = {
          name: this.companyName,
          adminName: this.username,
          adminPass: sha256(this.password),
          companyLang: this.language,
          useStaffManagement: this.useStaffManagement,
        }
        await CompanyService.createCompany(payload)
        this.reset()
        this.$vs.loading.close()
        this.$vs.notify({
          text: this.lang.companies.create.success[this.languageSelected],
          color: 'success'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data[0]
        ) {
          errorHandler(error.response.data[0])
        } else {
          this.$vs.notify({
            title: 'Oops!',
            text: this.lang.companies.create.error[this.languageSelected],
            color: 'danger'
          })
        }
      }
    },
    reset() {
      this.errors.clear()
      this.companyName = null
      this.username = null
      this.password = null
      this.confirmPassword = null
      this.submitted = false
    },
    cancel() {
      this.$router.push('/companies')
    },
    checkPassword() {
      // const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password.length >= 8) {
        this.passwordValidations.containsEightCharacters = true
      } else {
        this.passwordValidations.containsEightCharacters = false
      }

      // this.passwordValidations.containsNumber = /\d/.test(this.password)
      // this.passwordValidations.containsUppercase = /[A-Z]/.test(this.password)
      // this.passwordValidations.containsSpecialCharacter = format.test(
      //   this.password
      // )

      if (
        this.passwordValidations.containsEightCharacters === true
        // &&
        // this.passwordValidations.containsSpecialCharacter === true &&
        // this.passwordValidations.containsUppercase === true &&
        // this.passwordValidations.containsNumber === true
      ) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    togglePassword() {
      const passwordInput = document.getElementById('password')
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text'
        this.passwordType = 'text'
      } else {
        passwordInput.type = 'password'
        this.passwordType = 'password'
      }
    },
    togglePasswordStrength() {
      this.showPasswordStrength = !this.showPasswordStrength
    },
    updateValidator() {
      Validator.localize(
        this.languageSelected,
        this.languageSelected === 'es' ? es : en
      )
    }
  },
  mounted() {
    this.updateValidator()
  }
}
</script>

<style lang="scss">
.create-company {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: unset;
    display: flex;
    flex-direction: column;

    .vs-card--content {
      margin-bottom: 0;
    }

    .form-wrapper {
      position: relative;

      .toggle-password {
        top: 25px;
        right: 10px;
      }

      .con-vs-dropdown--menu {
        padding-top: unset;
        left: 540px;
        top: 15px;
        width: 220px;

        .vs-dropdown--menu {
          padding: 10px !important;
        }

        .vs-dropdown--menu--after {
          right: 210px;
          top: 20px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          border-top: none;
        }
      }

      .password-strength {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          list-style-type: disc !important;
          margin-bottom: 8px;
          color: #525f7f;
          position: relative;
        }

        li:before {
          content: '';
          width: 0%;
          height: 2px;
          background: #2ecc71;
          position: absolute;
          left: 0;
          top: 50%;
          display: block;
          transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        .is_valid {
          color: rgba(136, 152, 170, 0.8);
        }
        .is_valid:before {
          width: 100%;
        }
      }

      .vs-con-input-label {
        width: 100%;
      }
    }
  }
}
</style>
